<div class="modal-main">
  <div class="close-modal">
    <button mat-icon-button mat-dialog-close class="close-button">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <div class="modal-header-container">
    <h3>Sign In</h3>
  </div>

  <div class="sign-in-modal-main" style="min-height: 100%">
    <mat-dialog-content style="display: block">
      <div *ngIf="showSpinner" style="min-width: 280px; min-height: 400px">
        <mat-progress-spinner
          color="primary"
          value="100"
          mode="indeterminate"
          style="margin-left: auto; margin-top: 40px; margin-right: auto"
          diameter="120"
          strokeWidth="8"
        >
        </mat-progress-spinner>
      </div>

      <div *ngIf="!showSpinner">
        <!-- EXT-2983 If revert delete this and below-->
        <div>
          <div class="welcome-text">
            <strong>WELCOME TO THE NEW AND</strong>
            <br />
            <strong>IMPROVED JFW WEBSITE!</strong>
          </div>
        </div>
        <div class="button-container">
          <strong>First time visiting this site?</strong>
          <button
            mat-flat-button
            color="primary"
            style="width: 250px"
            (click)="onCreateAccount()"
          >
            Create a New Account
          </button>
        </div>

        <div class="button-container">
          <div class="welcome-text">
            <strong> This site look different to you? </strong>
            <br />
            <p style="font-size: 12px; margin: 0px">
              Recreate your account to view your event
            </p>
          </div>
          <button
            mat-flat-button
            color="primary"
            style="width: 250px"
            (click)="onCreateAccount()"
          >
            Recreate My Account
          </button>
        </div>

        <div class="button-container">
          <div class="welcome-text">
            <strong> This site looks familiar! </strong>
            <p style="font-size: 12px; margin: 0px">Sign in below:</p>
          </div>
        </div>
        <!-- EXT-2983 If revert delete this and above-->
        <div
          style="
            text-align: center;
            width: 100%;
            margin-bottom: 10px;
            font-size: 14px;
            color: red;
          "
          *ngIf="!!errorMessage"
        >
          <strong>{{ errorMessage }}</strong>
          <!-- <strong *ngIf="errorCode === 'email-not-verified'"
            >Your email address has not been verified. Please check your email
            for a link we sent to you.</strong
          >

          <strong
            *ngIf="
              errorCode === 'auth/user-not-found' ||
              errorCode === 'auth/wrong-password'
            "
            >Invalid email/password combination</strong
          > -->
        </div>

        <div
          style="
            text-align: left;
            width: 100%;
            margin-bottom: 10px;
            font-size: 14px;
          "
        >
          * Required
        </div>

        <form [formGroup]="signInForm" (ngSubmit)="onSignIn()">
          <mat-form-field
            subscriptSizing="dynamic"
            appearance="outline"
            style="margin-bottom: 20px"
            [ngClass]="
              signInForm.get('email')?.invalid &&
              signInForm.get('email')?.touched
                ? 'error'
                : ''
            "
          >
            <mat-label>Email</mat-label>
            <input type="email" matInput formControlName="email" />
            <mat-error
              *ngIf="getFirstError(signInForm.controls.email) as error"
            >
              {{ error }}
            </mat-error>
            <!-- <mat-error>
              <span *ngIf="signInForm.get('email')?.errors?.required">
                Email is required.
              </span>
              <span *ngIf="signInForm.get('email')?.errors?.email">
                Email is not valid.
              </span>
            </mat-error> -->
          </mat-form-field>

          <mat-form-field
            subscriptSizing="dynamic"
            appearance="outline"
            style="margin-bottom: 10px"
            [ngClass]="
              signInForm.get('password')?.invalid &&
              signInForm.get('password')?.touched
                ? 'error'
                : ''
            "
          >
            <mat-label>Password</mat-label>
            <mat-icon matSuffix color="primary" (click)="hide = !hide">{{
              hide ? "visibility_off" : "visibility"
            }}</mat-icon>
            <input
              [type]="hide ? 'password' : 'text'"
              matInput
              formControlName="password"
            />
            <mat-error
              *ngIf="getFirstError(signInForm.controls.password) as error"
            >
              {{ error }}
            </mat-error>
          </mat-form-field>

          <div style="text-align: left; margin-bottom: 10px">
            @if (showRememberMe) {
              <mat-checkbox id="rememberMe" (change)="chkRememberMe($event)">
                Remember Me
              </mat-checkbox>
            }

            <p
              style="
                font-size: 12px;
                margin-left: 10px;
                margin-top: 3px;
                margin-bottom: 20px;
              "
            >
              If you forgot your password, you can
              <a
                style="text-decoration: underline; cursor: pointer"
                (click)="onForgotPassword()"
                >reset it</a
              >.
            </p>
          </div>

          <button
            mat-flat-button
            color="primary"
            type="submit"
            [disabled]="!signInForm.valid"
            style="width: 250px"
          >
            LOGIN
          </button>
        </form>

        <!-- If we revert EXT-2983 uncomment this -->
        <!-- <p style="margin-top: 5px; margin-bottom: 5px">- or -</p>

        <button
          mat-flat-button
          color="primary"
          style="width: 250px"
          (click)="onCreateAccount()"
        >
          Create a New Account
        </button> -->
        <br />
        <a
          *ngIf="!hasProdMode()"
          (click)="onGuestLogout()"
          style="font-size: 12px"
        >
          Logout of Prod Demo Mode
        </a>
      </div>
    </mat-dialog-content>
  </div>
</div>
