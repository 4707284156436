import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { requireAppCheck } from '@jfw-library/shared/app-check';
import { Category, EcomEnsemble, FilterCategory } from 'common-types';
import { BehaviorSubject, Observable, Subject, catchError, of } from 'rxjs';

export type triggerEnsembleSearchOptions = {
  triggerSearch: boolean;
  resetFilterParams?: boolean;
};

@Injectable({
  providedIn: 'root',
})
export class EnsembleService {
  private apiUrl = this.environment.ecom_ensemble_rest_api_server_url;
  private reqHeader = new HttpHeaders({
    'Content-Type': 'application/json',
  });
  public filterCategories = new BehaviorSubject<FilterCategory[]>([]);
  public triggerEnsembleSearch = new Subject<triggerEnsembleSearchOptions>();
  public searchEnsembles = new Subject<void>();
  public showFilter = new BehaviorSubject<boolean>(false);
  public searchText = new BehaviorSubject<string>('');

  constructor(
    @Inject('environment') private environment: any,
    private httpClient: HttpClient,
  ) {}

  private handleError(errorResult: any) {
    return (err: HttpErrorResponse) => {
      // just a test ... more could would go here
      console.log('WE ARE HANDLING ENSEMBLE SERVICE ERROR', err);
      //return throwError(() => err);
      // return new Observable<never>();
      return of(errorResult);
    };
  }

  public getAllEnsembles(): Observable<EcomEnsemble[]> {
    return this.httpClient
      .get<EcomEnsemble[]>(this.apiUrl, {
        ...requireAppCheck,
        headers: this.reqHeader,
      })
      .pipe(
        //tap((data) => console.log('All: ', JSON.stringify(data))),
        catchError(this.handleError([])),
      );
  }

  public getEnsembleByEnsembleCode(
    ensembleCode: string,
    ensembleGroupCode?: string,
  ): Observable<EcomEnsemble> {
    let requestUrl = '';
    if (ensembleGroupCode) {
      requestUrl = `${this.apiUrl}/ensembleCode/${ensembleCode}/ensembleGroupCode/${ensembleGroupCode}`;
    } else {
      requestUrl = `${this.apiUrl}/ensembleCode/${ensembleCode}`;
    }
    return this.httpClient
      .get<EcomEnsemble>(requestUrl, {
        ...requireAppCheck,
        headers: this.reqHeader,
      })
      .pipe(catchError(this.handleError({})));
  }

  public getTrendingEnsembles(): Observable<EcomEnsemble[]> {
    return this.httpClient
      .get<EcomEnsemble[]>(`${this.apiUrl}/trending`, {
        ...requireAppCheck,
        headers: this.reqHeader,
      })
      .pipe(catchError(this.handleError([])));
  }

  // public getRentalEnsemblesByCategory(
  //   category: Category
  // ): Observable<EcomEnsemble[]> {
  //   return this.httpClient
  //     .get<EcomEnsemble[]>(`${this.apiUrl}/rental/${category}`, {
  //       ...requireAppCheck,
  //       headers: this.reqHeader,
  //     })
  //     .pipe(catchError(this.handleError([])));
  // }

  public getPurchaseEnsemblesByCategory(
    category: Category,
  ): Observable<EcomEnsemble[]> {
    return this.httpClient
      .get<EcomEnsemble[]>(`${this.apiUrl}/purchase/${category}`, {
        ...requireAppCheck,
        headers: this.reqHeader,
      })
      .pipe(catchError(this.handleError([])));
  }
}
