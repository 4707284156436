import { isPlatformBrowser } from '@angular/common';
import { inject, Inject, Injectable, PLATFORM_ID } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { generateUUID } from 'business-logic';
import {
  DealerPortalEnvironment,
  EcommerceMainEnvironment,
} from 'common-types';
import { SsrCookieService } from 'ngx-cookie-service-ssr';
import { AnonAuthService } from '../../services/anon-auth/anon-auth.service';
import { AuthService } from '../../services/auth/auth.service';

/**
 * Runs through a series of checks to determine if page is accessible.
 * On Ecommerce Main, all routes that are meant to be accessible by a non-logged-in user (anon user) have the AnonTokenGuard.  This guard checks for five scenarios (in this order):
 * 1. Prod Mode Wide Open - If the site is in Prod Mode Wide Open, then check for the AnonToken cookies.  If all three cookies exist, allow access.  If not, initialize a new AnonToken and allow access.
 * 2. IsLoggedIn - checks to see if user has ecomAccess custom claims.
 *    - If has ecomAccess, allow access.
 *    - Else, proceed to next step.
 * 5. Prod Mode NOT Wide Open - Check to see if AnonToken cookies exist:
 *    - If either AnonToken cookie does not exist, redirect to login because the AnonToken needs to be initialized by the V2login (or the user can choose to enter via /prod).
 *    - If both cookies exist, check to see if token_prod_validated cookie exists.
 *        - If it exists, it’s too soon to revalidate, so allow access.
 *        - If it doesn’t exist, it’s time to validate the token again.
 *            1. If the token is still valid, allow access.
 *            2. If the token is no longer valid, then initialize a new token.
 *                - If the token refresh is successful, allow access.
 *                - If the token refresh fails, redirect to login.
 */
@Injectable({
  providedIn: 'root',
})
export class AnonTokenGuard {
  constructor(
    @Inject('environment')
    private environment: EcommerceMainEnvironment | DealerPortalEnvironment,
    private router: Router,
    private authService: AuthService,
    private cookieService: SsrCookieService,
    private anonAuthService: AnonAuthService,
  ) {}

  private isBrowser = isPlatformBrowser(inject(PLATFORM_ID));

  /**
   *
   * @returns True if token_prod cookie exists
   */
  private tokenProdCookieExists() {
    return this.cookieService.check('token_prod');
  }

  /**
   *
   * @returns True if token_prod_session_userid cookie exists
   */
  private tokenSessionUserIdCookieExists() {
    return this.cookieService.check('token_prod_session_userid');
  }

  /**
   *
   * @returns True if token_prod_validated cookie exists
   */
  private tokenValidatedCookieExists() {
    return this.cookieService.check('token_prod_validated');
  }

  /**
   * 1. Checks if token_prod_session_userid cookie exists to use as userId.
   *    - If cookie exists, use it as userId
   *    - If cookie does not exist, generate a new uuid and use it as userId.
   * 2. Calls  [anonAuthService.initAnonToken()](../../services/anon-auth/anon-auth.service.ts) to create a new token.
   * @returns true if token was successfully initialized, false if not.
   */
  private async initAnonToken(): Promise<boolean> {
    let userId: string = '';
    if (this.tokenSessionUserIdCookieExists()) {
      userId = this.cookieService.get('token_prod_session_userid');
    } else {
      userId = generateUUID(this.isBrowser);
    }

    const tokenInitResponse = await this.anonAuthService.initAnonToken(userId);
    return tokenInitResponse;
  }

  /**
   * Calls the [anonAuthService.validateToken()](../../services/anon-auth/anon-auth.service.ts) to validate the AnonToken
   * (the token_prod and token_prod_session_userid cookies).
   * NOTE: This will return false if the validation attempt is too soon.
   * @returns true only if api returns a valid response (no error) and the response.valid property is true.  Otherwise returns false.
   */
  private async isValidToken(): Promise<boolean> {
    console.log('> validating anon token');

    try {
      const res = await this.anonAuthService.validateToken(
        this.cookieService.get('token_prod_session_userid'), // userId
        this.cookieService.get('token_prod'), // JWT
      );

      if (res !== undefined && res && res.valid) {
        return true;
      }

      return false;
    } catch (error: any) {
      return false;
    }
  }

  /**
   * Clears the token_prod, token_prod_validated, and token_refresh_key cookies, and optionally the token_prod_session_userid cookie if deleteSessionUserIdCookie is true.
   * @param deleteSessionUserIdCookie If true, also deletes the token_prod_session_userid cookie.
   */
  private clearAnonTokenCookies(deleteSessionUserIdCookie: boolean = false) {
    this.anonAuthService.clearAnonTokenCookies(deleteSessionUserIdCookie);
  }

  /**
   * Clears the AnonToken cookies and redirects to the V2login page.
   * @returns Promise of router navigation (boolean)
   */
  private async redirectToLogin() {
    this.clearAnonTokenCookies();
    return await this.router.navigate(['V2login']);
  }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    /*	--------------------------------------------------
        Each "Part" checks the following main scenarios:
        1. Prod Mode Wide Open -> check for anon cookies (or initialize with new token)
        2. Is Logged In -> check for ECOM access
        3. Prod Mode NOT Wide Open -> check for anon cookies (or redirect to login)
    --------------------------------------------------- */

    try {
      console.log(
        '%c* ANON TOKEN GUARD START *',
        'background-color: yellow;color:black',
      );

      if (this.authService.isProdModeWideOpen) {
        console.log('Part 1: Prod Mode Wide Open');
        if (!this.isBrowser) {
          console.log(' > Server Side Rendered: ALLOW ACCESS');
          return true;
        }
        const tokenProdCookie = this.cookieService.get('token_prod');
        const tokenSessionUserIdCookie = this.cookieService.get(
          'token_prod_session_userid',
        );
        const tokenValidatedCookie = this.cookieService.get(
          'token_prod_validated',
        );
        console.log('tokenProdCookie', tokenProdCookie);
        console.log('tokenSessionUserIdCookie', tokenSessionUserIdCookie);
        console.log('tokenValidatedCookie', tokenValidatedCookie);
        // if all three cookies exist, then we are good to go
        if (
          this.tokenProdCookieExists() &&
          this.tokenSessionUserIdCookieExists() &&
          this.tokenValidatedCookieExists()
        ) {
          console.log(' > already validated: ALLOW ACCESS');
          return true;
        }

        // Clearing token_prod_validated to prevent getToken issue
        // TODO: Reevaluate. I think this might defeat the purpose.
        // this.cookieService.delete('token_prod_validated');

        // We don't have all three cookies, so initialize with a new token
        console.log(' > refresh Anon Token');
        const initToken = await this.initAnonToken();

        console.log(' > initToken success: ALLOW ACCESS');
        // TODO: there is no error handling or check for initToken.
        return true;
      } else {
        console.log('Part 1: Prod Mode NOT Wide Open');
      }

      /// Part 2: Is Logged In
      const isLoggedIn = await this.authService.isLoggedIn;
      if (isLoggedIn) {
        console.log('Part 2: isLoggedIn');
        const hasEcomAccess = await this.authService.getUserHasEcomAccess(
          true,
          false,
        );

        if (hasEcomAccess) {
          console.log(' > ecomAccess: ALLOW ACCESS');
          return true;
        }

        /// If we got here, the user was logged in, but did NOT have ECOM access.
        console.log(' > User is loggedIn BUT does NOT have EcomAccess');
      } else {
        console.log('Part 2: NOT Logged In');
      }

      // Part 3: Prod NOT Wide Open
      console.log('Part 3: Prod Mode NOT Wide Open');

      /// If NOT token_prod or NOT userid then back to login because the user needs to either log in or initialize a new token through the prod-login page.
      if (
        !this.tokenProdCookieExists() ||
        !this.tokenSessionUserIdCookieExists()
      ) {
        console.log(' > tokenProd OR userId does not exist > go to login');
        await this.redirectToLogin();

        return false;
      }

      ///  If we are this far, we have both AnonToken cookies, so check if already validated
      if (this.tokenValidatedCookieExists()) {
        console.log(' > validatedCookie exists > ALLOW ACCESS');

        return true;
      }

      /// We know that token_prod and userid should exist at this point.
      /// We also know that token_prod_validated does NOT exist, so it is not too soon to validate.
      /// Check if they are Valid.
      const isValidToken = await this.isValidToken();

      if (isValidToken) {
        /// Token is valid.
        console.log(' > token/userId is valid ... ');

        // Clearing token_prod_validated to prevent getToken issue
        // TODO: Reevaluate. I think this might defeat the purpose.
        // this.cookieService.delete('token_prod_validated');

        //Refresh the token information
        const initTokenResponse = await this.initAnonToken();

        if (initTokenResponse) {
          console.log(' >> token refresh successful > ALLOW ACCESS');

          return true;
        }
      } else {
        /// Token is NOT valid.
        console.log(' > token/userId is NOT valid > go to login');
      }

      await this.redirectToLogin();
      return false;
    } catch (error: any) {
      console.log('AnonToken Guard Error');
      console.log(error);
      return false;
    }
  }
}
