import { convertDateToUnixTimestamp, getMinDateForOnlineEvent, getMinEventDateToday } from "business-logic";
import { EventNameSchema, EventSchema } from "common-types";
import { z } from "zod";
import { EVENT_DATE_TOO_SOON_MESSAGE } from "./event-date-too-soon-message";

// export const EcomEventDetailsFormSchema = EventDetailsFormSchema.extend({
//   individualOrGroup: z.enum(['individual', 'group', '']),
//   onlineOrInStore: z.enum(['online', 'inStore', '']),
// });

const EventDetailsFormSchema = z.object({
  eventName: EventNameSchema
    .or(z.literal(''))
    .refine(val => val.length > 0, { message: 'Event name is required' }), // Necessary because '' is an allowed value
  eventType: EventSchema.shape.eventType
    .or(z.literal('')),
  eventDate: EventSchema.shape.eventDate
    .refine(val => val !== 0, { message: 'Event date is required' }),
  // eventDate: z.object({
  //   date: z.date()
  // })
  //   .transform(({ date }) => convertDateToUnixTimestamp(date.toDateString()))
  //   // .superRefine((val, ctx) => {
  //   //   console.log("val in superRefine: ", val);

  //   //   const parsed = EventSchema.shape.eventDate.safeParse(val);
  //   //   if (!parsed.success) {
  //   //     const message = parsed.error.issues[0].message;
  //   //     console.log({ parsed, message });
  //   //     ctx.addIssue({ code: z.ZodIssueCode.custom, fatal: true, message })
  //   //   }
  //   // })
  //   .optional(),
})



type EventDetailsForm = z.infer<typeof EventDetailsFormSchema>;



export const IndividualOrGroupSchema = z.enum(['individual', 'group', '']);
export const IndividualOrGroupOptions = IndividualOrGroupSchema.options;
export const IndividualOrGroup = {
  individual: IndividualOrGroupSchema.enum.individual,
  group: IndividualOrGroupSchema.enum.group,
} as const;
export type IndividualOrGroupType = z.infer<typeof IndividualOrGroupSchema>;




export const OnlineOrInStoreSchema = z.enum(['online', 'inStore', '']);
export const OnlineOrInStoreOptions = OnlineOrInStoreSchema.options;
export const OnlineOrInStore = {
  online: OnlineOrInStoreSchema.enum.online,
  inStore: OnlineOrInStoreSchema.enum.inStore,
} as const;
export type OnlineOrInStoreType = z.infer<typeof OnlineOrInStoreSchema>;




export const EcomEventDetailsFormSchema = EventDetailsFormSchema.extend({
  individualOrGroup: IndividualOrGroupSchema,
  onlineOrInStore: OnlineOrInStoreSchema,
}).refine(val => {
  const { eventDate, onlineOrInStore } = val;
  return onlineOrInStore === OnlineOrInStore.online
    ? eventDate >= convertDateToUnixTimestamp(getMinDateForOnlineEvent().toDateString())
    : eventDate >= convertDateToUnixTimestamp(getMinEventDateToday().toDateString());
},
  { message: EVENT_DATE_TOO_SOON_MESSAGE }
);

export type EcomEventDetailsForm = z.infer<typeof EcomEventDetailsFormSchema>;
