import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { requireAppCheck } from '@jfw-library/shared/app-check';
import { VerificationCodeV1Types as v1 } from 'common-types';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class EcomUserVerification_V1_ApiService {
  private readonly apiUrl = '/api/1/userVerification';

  private readonly httpOptions = {
    ...requireAppCheck,
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  constructor(private httpClient: HttpClient) {}

  /******************************************************
   *                       UPDATE                       *
   ******************************************************/

  public sendVerificationCode(
    email: string
  ): Observable<v1.SendVerificationCodeSuccessResponse> {
    const route = `${this.apiUrl}/sendVerificationCode/${email}`;

    return this.httpClient.put<v1.SendVerificationCodeSuccessResponse>(
      route,
      {},
      this.httpOptions
    );
  }

  public verifyEmail(
    email: string,
    code: number
  ): Observable<v1.VerifyEmailSuccessResponse> {
    const route = `${this.apiUrl}/verifyEmail/${email}/${code}`;

    return this.httpClient.put<v1.VerifyEmailSuccessResponse>(
      route,
      {},
      this.httpOptions
    );
  }
}
