import {
  AbstractControl,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn
} from '@angular/forms';
import { AnyZodObject, ZodEffects, ZodObject, ZodType, ZodTypeAny } from 'zod';

function isZodEffects(obj: any): obj is ZodEffects<any> {
  return obj && typeof obj.innerType === 'function';
  // return obj instanceof ZodEffects
}

function isZodObject(obj: any): obj is ZodObject<any, any, any> {
  return obj && obj.shape !== undefined;
  // return obj instanceof ZodObject
}


export abstract class ZodFormUtilities {


  /// START zodFormControlValidator
  /** Validate an individual form field associated with a Zod schema.
   * This will provide errors at the level of the individual field.
   * This should only be used on FormControls, not FormGroups.  Use zodFormGroupValidator for FormGroups.
   * This can be used on a single FormControl that is not part of a FormGroup or on a FormControl that is part of a FormGroup.
   * You can use a combination of zodFormControlValidator and zodFormGroupValidator on the same form as long as they are each used on the correct type of control.
   * See examples below.
   *
   * @param inputSchema The Zod schema to validate against.  This can be a schema specific to a FormControl or a schema for the whole form.
   * If a schema for the whole form is provided, the validator will find the correct key in the schema to validate against.
   * If a schema for a FormControl is provided, the validator will validate the FormControl's value against the schema.
   * This allows more flexibility to substitute a different schema for an individual FormControl than the one used for the whole form.
   * @param fieldName Optional - This is primarily used for debugging.
   * @returns A validator function that can be used in a FormControl
   * @example
   * // Create a schema
   * const contactFormSchema = z.object({
   *  firstName: z.string().min(2, { message: "First name must be at least 2 characters." }),
   *  lastName: z.string().min(2, { message: "Last name must be at least 2 characters." }),
   *  address: z.object({
   *    street: z.string().min(2, { message: "Street must be at least 2 characters." }),
   *   city: z.string().min(2, { message: "City must be at least 2 characters." }),
   *   state: z.string().min(2, { message: "State must be at least 2 characters." }),
   *  zip: z.string().min(2, { message: "Zip must be at least 2 characters." }),
   * }),
   * });
   *
   * // Create a FormGroup using the top-level form schema with the zodFormControlValidator for each field.
   * const form = new FormGroup({
   *  firstName: new FormControl("", [ZodFormUtilities.zodFormControlValidator(contactFormSchema, "firstName")]),
   *  lastName: new FormControl("", [ZodFormUtilities.zodFormControlValidator(contactFormSchema, "lastName")]),
   *  address: new FormGroup({
   *    street: new FormControl("", [ZodFormUtilities.zodFormControlValidator(contactFormSchema, "street")]),
   *    city: new FormControl("", [ZodFormUtilities.zodFormControlValidator(contactFormSchema, "city")]),
   *    state: new FormControl("", [ZodFormUtilities.zodFormControlValidator(contactFormSchema, "state")]),
   *    zip: new FormControl("", [ZodFormUtilities.zodFormControlValidator(contactFormSchema, "zip")]),
   *  }),
   * });
   *
   * // Or you can create a schema for a single field
   * const firstNameSchema = z.string().min(2, { message: "First name must be at least 2 characters." });
   *
   * // You can use the single field schema on a single FormControl that is not part of a FormGroup.
   * const firstNameControl = new FormControl("", [ZodFormUtilities.zodFormControlValidator(firstNameSchema, "firstName")]);
   *
   * // You can also use a combination of top-level form schema and individual field schema.  Notice the firstNameSchema is used for the firstName FormControl.
   * const form = new FormGroup({
   *  firstName: new FormControl("", [ZodFormUtilities.zodFormControlValidator(firstNameSchema, "firstName")]),
   *  lastName: new FormControl("", [ZodFormUtilities.zodFormControlValidator(contactFormSchema, "lastName")]),
   *  address: new FormGroup({
   *    street: new FormControl("", [ZodFormUtilities.zodFormControlValidator(contactFormSchema, "street")]),
   *    city: new FormControl("", [ZodFormUtilities.zodFormControlValidator(contactFormSchema, "city")]),
   *    state: new FormControl("", [ZodFormUtilities.zodFormControlValidator(contactFormSchema, "state")]),
   *    zip: new FormControl("", [ZodFormUtilities.zodFormControlValidator(contactFormSchema, "zip")]),
   *  }),
   * });
   */
  static zodFormControlValidator<T extends ZodTypeAny>(
    inputSchema: T,
    fieldName?: string
  ): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      // console.log("start zodFormControlValidator");
      if (!(control instanceof FormControl)) {
        // console.error(
        //   'zodFormControlValidator for ',
        //   fieldName,
        //   ': Control is not a FormControl.  This validator should be used on FormControls only.  Use zodFormGroupValidator for FormGroups.'
        // );
        // return { devError: 'Control is not a FormControl.' }; // This is a developer error, not a user error.
        throw new Error(`zodFormControlValidator for ${fieldName}: Control is not a FormControl.  This validator should be used on FormControls only.  Use zodFormGroupValidator for FormGroups.`); // This is a developer error, not a user error.
      }

      // console.log("zodFormControlValidator for ", fieldName, " control: ", control);

      const { value, parent } = control;

      if (!inputSchema) {
        console.log('zodFormControlValidator: Schema is null.');
        return null;
      }

      if (!control) {
        console.log('zodFormControlValidator: Control is null.');
        return null;
      }

      // If schema is for a FormGroup, then it will be of type AnyZodObject and will have a shape property,
      // but if the schema is a ZodEffects (was transformed or refined), then we need to unwrap the schema from the effects wrapper.
      const inputIsObjectSchema = isZodObject(inputSchema);
      const inputIsEffectsSchema = isZodEffects(inputSchema);
      const inputIsEffectsObjectSchema = inputIsEffectsSchema && isZodObject(inputSchema._def.schema);

      const schemaType = inputIsEffectsSchema
        ? inputIsEffectsObjectSchema
          ? inputSchema._def.schema as ZodObject<typeof inputSchema._output>
          : inputSchema._def.schema as ZodType<typeof inputSchema._output>
        : inputIsObjectSchema
          ? inputSchema
          : inputSchema

      const schema = schemaType
      const isObjectSchema = isZodObject(schema);
      // console.log("zodFormControlValidator: ", { inputIsObjectSchema, inputIsEffectsSchema, inputIsEffectsObjectSchema, isObjectSchema, });




      // Four Cases to Handle
      // 1. Schema is an object and there is a parent (control is part of a FormGroup) - find the correct key in schema to parse, parse the control's value against the schema
      // 2a. Schema is an object and there is no parent BECAUSE parent is null at the moment (temporary condition)
      // 2b. Schema is an object and there is NOW a parent (control is part of a FormGroup) - parse the controls value against the schema.
      // 3a. Schema is non-object type and there is no parent BECAUSE parent is null at the moment (temporary condition)
      // 3b. Schema is non-object type and there is NOW a parent (control is part of a FormGroup) - parse the controls value against the schema.
      // 4. Schema is non-object type and there is no parent (single-field FormControl) - parse the controls value against the schema

      // Case 1
      if (!!parent && isObjectSchema) {
        if (parent instanceof FormGroup) {
          console.log(
            'zodFormControlValidator for',
            fieldName,
            ": Case 1 -- Schema is an object and control's parent is a FormGroup."
          );

          const key = Object.keys(parent.controls).find((key) => {
            const isControl = control === parent.controls[key];
            return isControl ? key : null;
          });

          if (!key) {
            // console.error('zodFormControlValidator: Key not found in parent.');
            // return { devError: 'Key not found in parent.' }; // This is a developer error, not a user error.  This should never happen.
            throw new Error(`zodFormControlValidator for ${fieldName}: Key not found in parent.`); // This is a developer error, not a user error.  This should never happen.
          }

          if (!(key in schema.shape)) {
            // console.error(
            //   'zodFormControlValidator: Key: ',
            //   key,
            //   ' not found in schema: ',
            //   schema.shape
            // );
            // return { devError: 'Key not found in schema.' }; // This is a developer error, not a user error.
            throw new Error(`zodFormControlValidator for ${fieldName}: Key: ${key} not found in schema: ${schema.shape}`); // This is a developer error, not a user error.
          }

          // const forParse = { [key]: value };

          // const fieldSchema = schema.pick({ [key]: true });
          const fieldSchema = schema.shape[key] as ZodType<T>;

          // console.log('fieldSchema for', key, ':', fieldSchema);


          const result = fieldSchema.safeParse(value);

          // console.log(result);

          if (result.success) {
            // console.log('No errors');
            return null;
          }

          // console.log(fieldSchema.shape, result.error.issues);

          // sort the errors so that required errors are listed first
          const sortedResult = result.error.issues.sort((a, b) => {
            if (a.message.includes('required')) {
              return -1;
            }
            if (b.message.includes('required')) {
              return 1;
            }
            return 0;
          });

          // // if multiple errors, return just the first one
          // return {
          //   schemaFieldError: sortedResult.map((issue) => issue.message)[0],
          // };

          // if multiple errors, return all of them
          return { schemaFieldErrors: sortedResult.map((issue) => issue.message) };
        }

        // Case 1 dev error - parent is not a FormGroup - this should never happen
        // console.error(
        //   'zodFormControlValidator: Control ',
        //   parent,
        //   'for ',
        //   fieldName,
        //   ' is not a FormGroup.'
        // );
        // return {
        //   devError:
        //     'Parent control ' +
        //     parent +
        //     'for ' +
        //     fieldName +
        //     ' is not a FormGroup.',
        // }; // This is a developer error, not a user error. This should never happen.
        throw new Error(`zodFormControlValidator for ${fieldName}: Parent control ${parent} for ${fieldName} is not a FormGroup.`); // This is a developer error, not a user error. This should never happen.
      }

      // Case 2, 3, 4
      const case2 = !parent && isObjectSchema;
      const case3 = !parent && !isObjectSchema;
      const case4 = !!parent && !isObjectSchema;
      // console.log(
      //   'zodFormControlValidator for ',
      //   fieldName,
      //   case2 ? 'Case 2' : case3 ? 'Case 3' : case4 ? 'Case 4' : 'No Case'
      // );

      const result = schema.safeParse(value);

      // console.log(result);

      if (result.success) {
        // console.log('No errors. ', result);
        return null;
      }

      // sort the errors so that required errors are listed first
      const sortedResult = result.error.issues.sort((a, b) => {
        if (a.message.includes('required')) {
          return -1;
        }
        if (b.message.includes('required')) {
          return 1;
        }
        return 0;
      });

      // console.log(result.error.issues);

      // if multiple errors, return just the first one
      // return {
      //   schemaFieldError: sortedResult.map((issue) => issue.message)[0],
      // };

      // if multiple errors, return all of them
      return { schemaFieldErrors: sortedResult.map((issue) => issue.message) };
    };
  } // END zodFormControlValidator



  /// START zodFormGroupValidator
  /** Validate a FormGroup using a Zod schema.
   * Put this validator on the top level of the FormGroup or on any nested FormGroup.  It will validate the entire form or just the nested FormGroup.
   * Errors will be set on the individual form controls and on any FormGroups, based on the schema.
   * This should only be used on FormGroups, not FormControls.  Use zodFormControlValidator for FormControls.
   * You can use a combination of zodFormControlValidator and zodFormGroupValidator on the same form as long as they are each used on the correct type of control.
   * @param schema The Zod schema to validate against.  This should be a schema for a FormGroup and not a schema for an individual field (FormControl).
   * However, the FormGroup can be a nested FormGroup.  See examples below.
   * @param schemaProp The property of the schema to validate against.  If not provided, the whole schema will be validated.
   * @param disableSubSchemaWarnings If true, then warnings about fields in the form that are not in the schema will be disabled.  This is useful when you have only a subset of the schema in the form.
   * @returns {ValidatorFn} A validator function that can be used in a FormGroup
   * @example
   * // Create a schema
   * const contactFormSchema = z.object({
   *  firstName: z.string().min(2, { message: "First name must be at least 2 characters." }),
   *  lastName: z.string().min(2, { message: "Last name must be at least 2 characters." }),
   *  address: z.object({
   *    street: z.string().min(2, { message: "Street must be at least 2 characters." }),
   *    city: z.string().min(2, { message: "City must be at least 2 characters." }),
   *    state: z.string().min(2, { message: "State must be at least 2 characters." }),
   *    zip: z.string().min(2, { message: "Zip must be at least 2 characters." }),
   *  }),
   * });
   *
   * // Create a FormGroup
   * const form = new FormGroup({
   *  firstName: new FormControl(""),
   *  lastName: new FormControl(""),
   *  address: new FormGroup({
   *    street: new FormControl(""),
   *    city: new FormControl(""),
   *    state: new FormControl(""),
   *    zip: new FormControl(""),
   *  }, [ZodFormUtilities.zodFormGroupValidator(contactFormSchema, "address")]), // validate just the address field, which is a nested FormGroup
   * });
   * }, [ZodFormUtilities.zodFormGroupValidator(contactFormSchema)]); // validate the whole form
   *
   */
  static zodFormGroupValidator<T extends AnyZodObject | ZodEffects<any>>(
    inputSchema: T,
    options: {
      schemaProp?: string,
      disableSubSchemaWarnings?: boolean
    } = {
        schemaProp: undefined,
        disableSubSchemaWarnings: false
      }
  ): ValidatorFn {
    return (form: AbstractControl): ValidationErrors | null => {

      const { schemaProp, disableSubSchemaWarnings } = options;

      if (!(form instanceof FormGroup)) {
        // return { devError: 'Control is not a FormGroup.' }; // This is a developer error, not a user error.
        throw new Error(`zodFormGroupValidator for ${schemaProp}:
        Control is not a FormGroup.  This validator should be used on FromGroups only.  Use zodFormControlValidator for individual FormControls.`); // This is a developer error, not a user error.
      }

      if (!form) {
        // console.log('zodFormGroupValidator: Form is null.');
        return null;
      }



      // recursive function to find the schema shape (._def.schema.shape ? ._def.schema : recurse deeper) from an inputSchema that may be wrapped in a ZodEffects
      const findSchemaShape = (schema: T): AnyZodObject | null => {
        if (isZodEffects(schema)) {
          return findSchemaShape(schema._def.schema)
        }
        if (isZodObject(schema)) {
          return schema
        }
        return null
      }

      // If schema is for a FormGroup, then it will be of type AnyZodObject and will have a shape property,
      // but if the schema is a ZodEffects (was transformed or refined), then we need to unwrap the schema from the effects wrapper.
      const schema = findSchemaShape(inputSchema);
      const isObjectSchemaAfterUnwrap = isZodObject(schema);
      // console.log("FORM GROUP VALIDATOR")
      // console.log({ inputSchema })
      // console.log({ inputIsObjectSchema, inputIsEffectsSchema, inputIsEffectsObjectSchema, isObjectSchemaAfterUnwrap, });

      const schemaKeys = isObjectSchemaAfterUnwrap ? Object.keys(schema.shape) : null
      const schemaKeysInForm = schemaKeys?.filter((key) => key in form.controls);

      // console.log({ inputSchema, schema, isObjectSchemaAfterUnwrap, schemaKeys, schemaKeysInForm });


      // First determine if this is a top-level form or a nested form.  If it is a nested form, then schemaProp should be defined.
      // If it is a top-level form, then schemaProp should be undefined
      // But need to check for dev error first.
      // For example, if it is a top-level schema, then one possible error would be including a field in the form that does not have a corresponding field in the schema.
      // If you have a schema with fields A, B, and C, but the form has a fields A, B, C, D, then the schema will not be able to validate the form because D is not in the schema.
      if (!schemaProp) {
        // Check if every form control is in the schema.
        const allControlsAreInSchema = isObjectSchemaAfterUnwrap
          ? Object.keys(form.controls).every((key) => key in schema.shape) // check that each key in the form is also in the schema.
          : false

        // Check if at least one key from the schema is in the form
        const hasAnySchemaKeys = isObjectSchemaAfterUnwrap ? Object.keys(schema.shape).some((key) => key in form.controls) : false


        /* Check for Dev Error: Form is not a top-level form and schemaProp is not defined,
        so we don't know which nested field to validate against.
        So there are two cases to handle:
        1. Form has fields that are not in the schema, but there is at least one field in the schema,
        so we can just validate the fields that ARE in the schema.  This is the SUBSET SCHEMA case.
        But if there are FormGroup fields that are not in the schema, this could be for one of two reasons:
            a. The developer did this on purpose in order to use only a portion of a schema and will validate the rest of the form with a different schema.
            so we should still validate the fields that ARE in the schema.  But we should still warn the developer that there are fields in the FormGroup that are not in the schema.
            This is also what allows us to use multiple schemas on the same FormGroup.
            b. The developer intended to use the schema on a nested FormGroup, but we don't have a schemaProp to tell us which key in the schema to validate against.
            In case b, we need to throw an error because we don't know which key in the schema to validate against.
        2. Form has no fields that are in the schema, so we can't validate anything.  This is the NO SCHEMA KEYS case.
        */

        // Error Case 2 (NO SCHEMA KEYS ): Form has no fields that are in the schema, so we can't validate anything.
        if (!allControlsAreInSchema && !hasAnySchemaKeys) {
          const message = 'Form is not a top-level form and schemaProp is not defined. ' +
            'This can occur if there are fields in the FormGroup that are not in the top-level fields of the schema AND none of the fields in the FormGroup are in the schema. ' +
            'If the intent is to use a specific field of the provided schema as the schema for this FormGroup, then you must provide a value for the schemaProp parameter, ' +
            'which should be a key in the schema that corresponds to this FormGroup.';
          throw new Error(`zodFormGroupValidator: ${message}`); // This is a developer error, not a user error.
          // return { devError: 'Form is not a top-level form and schemaProp is not defined.', }; // This is a developer error, not a user error.
        }

        /// ********** SUBSET SCHEMA **********
        /// Form does not have all the keys from the schema, but it does have some of them.
        if (hasAnySchemaKeys && !allControlsAreInSchema) {

          // Warn the developer that there are fields in the FormGroup that are not in the schema.  Will still proceed to validate the fields that ARE in the schema.
          if (!disableSubSchemaWarnings) {
            const message = `Form is not a top-level form and schemaProp is not defined.
          This can occur if there are fields in the FormGroup that are not in the top-level fields of the schema.
          If the intent is to use a subset of the provided schema, then you must provide a value for the schemaProp parameter,
          which should be a key in the schema that corresponds to this FormGroup.
          `
            console.warn(`zodFormGroupValidator for ${schemaProp}: ${message}`); // This is a developer warning, not a user error.
          }
          // console.log("SUBSET SCHEMA, FORM HAS ALL NECESSARY KEYS");

          // At this point we know it is NOT a top-level form, but we know that the form has some of the necessary keys from the schema.
          // So validate only the fields from the form that are in the schema.
          const forParse = schemaKeysInForm?.reduce((prev, key) => {
            const obj = { [key]: form.controls[key].value };
            return { ...prev, ...obj };
          }, {} as { [key: string]: any })
          // console.log("forParse: ", forParse);
          const result = inputSchema.safeParse(forParse);
          // console.log('zodFormGroupValidator result: ', result);
          if (result.success) {
            return null;
          }
          // console.log('zodFormGroupValidator error flatten: ', result.error.flatten());
          const { fieldErrors, formErrors } = result.error.flatten();
          const fieldErrorsKeys = Object.keys(fieldErrors);
          // For each field that has a schema error, set the errors on the form control
          fieldErrorsKeys.forEach((key, index) => {
            const control = form.get(key);
            if (control) {
              const errors = control.errors;
              const isLast = index === fieldErrorsKeys.length - 1;
              const newErrors = { ...errors, schemaFieldErrors: fieldErrors[key] };
              control.setErrors({ ...newErrors }, { emitEvent: isLast });
            }
          });
          return null; // returning null because errors are manually set on the form controls above

        } // END SUBSET SCHEMA

        /// ********** TOP-LEVEL FORM **********
        // At this point we know this is a top-level form, so validate the whole schema
        // console.log("TOP LEVEL FORM")
        const forParse = { ...form.value };
        // console.log("forParse: ", forParse)
        const result = inputSchema.safeParse(forParse);
        // console.log('zodFormGroupValidator result: ', result);
        if (result.success) {
          return null;
        }

        // type FormattedErrors = inferFormattedError<typeof inputSchema>
        // const formattedErrors = result.error.format() as FormattedErrors;

        const { fieldErrors, formErrors } = result.error.flatten();
        const fieldErrorsKeys = Object.keys(fieldErrors);

        // // recursively unwrap the formatted errors object,
        // const setErrorsOnControls = (errors: FormattedErrors, path: string[]) => {
        //   const keys = Object.keys(errors) as (keyof typeof errors)[]; // this is the list of keys at this level of the object
        //   const numKeys = keys.length; // this is the number of keys at this level of the object

        //   // this should never happen because error object should always have at least one key
        //   if (numKeys === 0) {
        //     return null
        //   }

        //   // if there is more than one key, then there are nested errors, so set errors for current level and recurse
        //   if(numKeys > 1) {
        //     errors._errors?.forEach((errorMessage: string, index) => {
        //       const control = form.get(path); // get the control from the form using the path
        //       if (control) {
        //         const errors = control.errors;
        //         const isLast = index === fieldErrorsKeys.length - 1;
        //         const newErrors = { ...errors, schemaFieldErrors: fieldErrors[key] };
        //         control.setErrors({ ...newErrors }, { emitEvent: isLast });
        //       }
        //     });
        //     keys.forEach((key: keyof typeof errors) => {
        //       const nestedErrors = errors[key];
        //       setErrorsOnControls(nestedErrors)
        //     })
        //   }


        //   const key = keys[0] as keyof FormattedErrors
        //   const value = errors[key];


        //   //
        // }

        // For each field (and nested field) that has a schema error, set the errors on the form control (or form control in nested FormGroup)
        fieldErrorsKeys.forEach((key, index) => {
          const control = form.get(key);
          if (control) {
            const errors = control.errors;
            const isLast = index === fieldErrorsKeys.length - 1;
            const newErrors = { ...errors, schemaFieldErrors: fieldErrors[key] };
            control.setErrors({ ...newErrors }, { emitEvent: isLast });
          }
        });

        return null; // returning null because errors are manually set on the form controls above
      } // END no schemaProp


      // At this point we know that schemaProp is defined, so we are validating a nested FormGroup
      /// This is a nested form, so validate just the fieldSchema (if it exists) using the provided schemaProp
      console.log("NESTED FORM GROUP validation");

      // First, make sure the schema is an object schema
      if (!isObjectSchemaAfterUnwrap) {
        throw new Error(`zodFormGroupValidator for ${schemaProp}: Schema is not an object schema.`); // This is a developer error, not a user error.
      }

      /// continue happy path for nested form...

      // Check if the schemaProp is in the schema
      const fieldSchema = schemaProp
        ? schemaProp in schema.shape
          ? schema.shape[schemaProp]
          : null
        : null;

      if (!fieldSchema) {
        const message = `The provided schemaProp: ${schemaProp} was not found in the provided schema.
        The schemaProp must be a key in the schema that corresponds to the nested FormGroup.
        If this schema is for the whole form, then the schemaProp argument should be left undefined.
        `;
        throw new Error(`zodFormGroupValidator error: ${message}`); // This is a developer error, not a user error.
      }



      // create an object with the keys and values from the form
      const values = Object.entries(form.controls).reduce(
        (prev, [key, control], i) => {
          const obj = { [key]: control.value };
          return { ...prev, ...obj };
        },
        {} as { [key: string]: any }
      );
      const result = fieldSchema.safeParse(values);
      // console.log('zodFormGroupValidator result: ', result);
      if (result.success) {
        return null;
      }
      // console.log('zodFormGroupValidator issues: ', result.error.issues);

      const { fieldErrors } = result.error.flatten();

      return { schemaFormErrors: fieldErrors };

    };
  } // END zodFormGroupValidator

  static zodTypeToForm<T>(type: T): FormGroup {
    const form = new FormGroup({});

    for (const key in type) {
      const value = type[key];
      if (value instanceof FormControl) {
        form.addControl(key, value);
        form
          .get(key)
          ?.addValidators(
            ZodFormUtilities.zodFormControlValidator(type as AnyZodObject)
          );
      } else if (value instanceof FormGroup) {
        form.addControl(key, value);
      } else {
        console.error('schemaToForm: Value is not a FormControl or FormGroup.');
      }
    }

    return form;
  }

  static zodSchemaToForm<T extends AnyZodObject>(schema: T): FormGroup {
    const form = new FormGroup({});

    for (const key in schema.shape) {
      const value = schema.shape[key];
      if (value instanceof FormControl) {
        form.addControl(key, value);
        form
          .get(key)
          ?.addValidators(ZodFormUtilities.zodFormControlValidator(schema));
      } else if (value instanceof FormGroup) {
        form.addControl(key, value);
      } else {
        console.error('schemaToForm: Value is not a FormControl or FormGroup.');
      }
    }

    form.addValidators(ZodFormUtilities.zodFormGroupValidator(schema));

    return form; //as FormGroup<ZodToForm<T>>;
  } /// END zodSchemaToForm

  /// START getAllFieldErrors
  /**
   * Returns an array of either error messages (for zod schemaFieldErrors) or keys (from any other validator).  If there are no errors, returns an empty array.
   * Zod errors are listed last (after other validator errors).  Use getAllFieldErrorsZodFirst to list zod errors first.
   *
   * @example
   * <mat-error
      *ngFor="let error of getAllFieldErrors(emailForm.controls.lastName)">
      {{ error }}
    </mat-error
   *
   * @param control the control to check for errors
   */
  static getAllFieldErrors(control: AbstractControl): string[] {
    const errors = control?.errors;

    if (!errors) {
      return [];
    }

    const errorKeys = Object.keys(errors);

    // Sort the errors so that schemaFieldErrors are at the end of the array.  This is so that if there are multiple errors, the errors from non-zodSchema validators will be returned first.
    const sorted = [
      ...errorKeys.sort((a, b) => {
        if (a === 'schemaFieldErrors') {
          return 1;
        }
        if (b === 'schemaFieldErrors') {
          return -1;
        }
        return 0;
      }),
    ];

    // schemaFieldErrors is an array of error messages, so flatMap is used to flatten the array of arrays into a single array of strings
    return sorted.flatMap((key) => {
      return key === 'schemaFieldErrors' ? errors[key] as string[] : key;
    });
  } /// END getAllFieldErrors

  /// START getAllFieldErrorsZodFirst
  /**
   * Returns an array of either error messages (for zod schemaFieldErrors) or keys (from any other validator).  If there are no errors, returns an empty array.
   * Zod errors are listed first (before other validator errors).  Use getAllFieldErrors to list non-zodSchema validator errors first.
   *
   * @example
   * <mat-error
      *ngFor="let error of getAllFieldErrorsZodFirst(emailForm.controls.lastName)">
      {{ error }}
    </mat-error
   *
   * @param control the control to check for errors
   */
  static getAllFieldErrorsZodFirst(control: AbstractControl): string[] {
    const errors = control?.errors;

    if (!errors) {
      return [];
    }

    const errorKeys = Object.keys(errors);

    // Sort the errors so that schemaFieldErrors are at the beginning of the array.  This is so that if there are multiple errors, the errors from zodSchema validators will be returned first.
    const sorted = [
      ...errorKeys.sort((a, b) => {
        if (a === 'schemaFieldErrors') {
          return -1;
        }
        if (b === 'schemaFieldErrors') {
          return 1;
        }
        return 0;
      }),
    ];
    // schemaFieldErrors is an array of error messages, so flatMap is used to flatten the array of arrays into a single array of strings
    return sorted.flatMap((key) => {
      // console.log('key: ', key, ' errors[key]: ', errors[key]);
      return key === 'schemaFieldErrors' ? errors[key] as string[] : key;
    });
  } /// END getAllFieldErrorsZodFirst

  /// START getFirstOfAllFieldErrors
  /**
   * Returns either an error message (for zod schemaFieldErrors) or key (from any other validator). If there are no errors, returns undefined.
   * Zod errors are listed last (after other validator errors).  Use getFirstOfAllFieldErrorsZodFirst to show zod errors first.
   *
   * @example // Basic usage
   * <mat-error *ngIf="getFirstOfAllFieldErrors(emailForm.controls.firstName) as error">
   *  {{ error }}
   * </mat-error>
   *
   *
   * @example // To show a custom error message for a specific non-zodSchema validator error
   * // component.ts
   * getEmailError = (control: AbstractControl) => {
   *  const error = ZodFormUtilities.getFirstOfAllFieldErrors(control);
   *  return error == 'invalidEmail' ? 'Please enter a valid email address.'
   *  : error == 'required' ? 'Email is required'
   *  : error;
   * };
   *
   * // component.html
   * <mat-error *ngIf="getEmailError(emailForm.controls.email) as error">
   *  {{ error }}
   * </mat-error>

   * @param control the control to check for errors
   */
  static getFirstOfAllFieldErrors(
    control: AbstractControl
  ): string | undefined {
    return ZodFormUtilities.getAllFieldErrors(control)[0];
  } /// END getFirstOfAllFieldErrors

  /// START getFirstOfAllFieldErrorsZodFirst
  /**
   * Returns either an error message (for zod schemaFieldErrors) or key (from any other validator). If there are no errors, returns undefined.
   * Zod errors are listed first (before other validator errors).  Use getFirstSchemaFieldErrorMessage to show non-zodSchema validator errors first.
   *
   * @example
   *
   * <mat-error *ngIf="getFirstOfAllFieldErrorsZodFirst(emailForm.controls.firstName) as error">
   *  {{ error }}
   * </mat-error>
   *
   * @param control the control to check for errors
   */
  static getFirstOfAllFieldErrorsZodFirst(
    control: AbstractControl
  ): string | undefined {
    const error = ZodFormUtilities.getAllFieldErrorsZodFirst(control)[0];
    // console.log('error: ', error);
    return error;
  }

  /// START getSchemaFieldErrorMessages
  /**
     * Returns an array of error messages for the given control.  Only returns error messages for schema errors.
     *
     * @example
     * <mat-error
        *ngFor="let error of getSchemaFieldErrorMessages(emailForm.controls.lastName)">
        {{ error }}
      </mat-error>

     * @param {AbstractControl} control
     * @returns {string[]}
     */
  static getSchemaFieldErrorMessages(control: AbstractControl): string[] {
    const errors = control?.errors;

    if (!errors) {
      return [];
    }

    const errorKeys = Object.keys(errors);

    return errorKeys
      .filter((key) => key === 'schemaFieldErrors')
      .flatMap((key) => {
        return errors[key] as string[];
      });
  } /// END getSchemaFieldErrorMessages

  /// START getFirstSchemaFieldErrorMessage
  /**
   * Returns just the first of any schema error messages for the given control.  Only returns error messages for schema errors.
   *
   * @example
   *
   * <mat-error *ngIf="getFirstSchemaFieldErrorMessage(emailForm.controls.firstName) as error">
   *  {{ error }}
   * </mat-error>
   *
   * @param {AbstractControl} control
   */
  static getFirstSchemaFieldErrorMessage(
    control: AbstractControl
  ): string | undefined {
    return ZodFormUtilities.getSchemaFieldErrorMessages(control)[0];
  }












  // SCHEMA FORM ERRORS
  static getSchemaFormErrorMessages(control: AbstractControl): string[] {
    const errors = control?.errors;

    if (!errors) {
      return [];
    }

    const errorKeys = Object.keys(errors);

    return errorKeys
      .filter((key) => key === 'schemaFormError')
      .map((key) => {
        return errors[key];
      });
  } /// END getSchemaFormErrorMessages

  /// START getFirstSchemaFormErrorMessage
  /**
   * Returns just the first of any schema error messages for the given control.  Only returns error messages for schema errors.
   *
   * @example
   *
   * <mat-error *ngIf="getFirstSchemaFieldErrorMessage(emailForm.controls.firstName) as error">
   *  {{ error }}
   * </mat-error>
   *
   * @param {AbstractControl} control
   */
  static getFirstSchemaFormErrorMessage(
    control: AbstractControl
  ): string | undefined {
    return ZodFormUtilities.getSchemaFormErrorMessages(control)[0];
  }






}
