import { Component, Inject, inject } from '@angular/core';
import { FormGroup } from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { ECOM_USER_VERIFICATION_API_SERVICES } from '@jfw-library/ecommerce/api-services';
import {
  CreateAccountFormControls,
  PasswordForm,
  PasswordFormSchema,
} from '@jfw-library/ecommerce/zod-forms';
import {
  AfterSignIn,
  DealerPortalEnvironment,
  EcommerceMainEnvironment,
  NewUserInfo,
  Site,
  isValidSite,
} from 'common-types';
import { AuthService } from '../../services/auth/auth.service';
import { SignInModalComponent } from '../sign-in-modal/sign-in-modal.component';
@Component({
  selector: 'app-create-account-modal',
  templateUrl: './create-account-modal.component.html',
  styleUrls: ['./create-account-modal.component.scss'],
})
export class CreateAccountModalComponent {
  created = false;
  showSpinner = false;
  errorMsg = '';
  loading = true;

  private userVerificationService = inject(
    ECOM_USER_VERIFICATION_API_SERVICES.v1,
  );

  constructor(
    @Inject('environment')
    private environment: EcommerceMainEnvironment | DealerPortalEnvironment,
    @Inject(MAT_DIALOG_DATA) private data: { afterSignIn?: AfterSignIn } | null,
    private authService: AuthService,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<CreateAccountModalComponent>,
  ) {}

  // ngOnInit(): void {
  // this.showSpinner = true;
  // navigator.geolocation.getCurrentPosition(
  //   (position) => {
  //     this.showSpinner = false;
  //     // Here check if they are in the US by calling a service to compare position.coords.lat, position.coords.long..
  //   },
  //   (err) => {
  //     this.showSpinner = false;
  //   },
  //   {
  //     enableHighAccuracy: false,
  //     timeout: 60000,
  //   }
  // );
  // }

  public async onFormSubmit(
    form: FormGroup<CreateAccountFormControls>,
  ): Promise<void> {
    // the site is needed for the sendEmailVerification api call so that the url link matches the correct site
    const site = this.environment.site;
    if (!isValidSite(site)) {
      throw new Error('Developer Error: Site must be valid.'); // Developer error
    }

    this.showSpinner = true;

    const passwordForm: PasswordForm = {
      newPassword: form.controls.newPassword.value,
      confirmNewPassword: form.controls.confirmNewPassword.value,
    };

    const passwordParsed = PasswordFormSchema.safeParse(passwordForm);

    if (!passwordParsed.success) {
      this.showSpinner = false;
      return;
    }

    const displayName = `${form.controls.firstName.value} ${form.controls.lastName.value}`;
    const email = form.controls.email.value;
    const password = passwordParsed.data.newPassword;

    const newUserInfo: NewUserInfo = {
      displayName: displayName,
      email: email,
      password: password,
    };

    let phoneNumber = form.controls.phone?.controls.phone?.value;

    if (phoneNumber) {
      newUserInfo.phoneNumber = `+1${phoneNumber}`;
    }

    try {
      /**
      The lastRoute is needed for the sendEmailVerification api call that is made after the account is created.
      This is passed along as the continueUrl query param for the email verification link.
      */
      const lastRoute = localStorage.getItem('lastRoute');

      const verifyEmailContinueUrl = lastRoute
        ? this.environment.prefix_for_email_action_links +
          decodeURIComponent(lastRoute)
        : undefined;

      const userRecord = await this.authService.createAccount(
        newUserInfo,
        site,
        verifyEmailContinueUrl,
      );

      this.showSpinner = false;
      if (userRecord && userRecord.uid) {
        this.created = true;
        if (!this.environment.dealerPortal) {
          this.userVerificationService
            .sendVerificationCode(userRecord.email!)
            .subscribe();
          this.dialogRef.close();
          this.authService.signInWithEmailAndPassword(
            Site.Ecom,
            email,
            password,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            this.data?.afterSignIn,
          );
        }
      }
    } catch (error: any) {
      this.showSpinner = false;
      this.errorMsg = error.message;
    }
  }

  public onSignIn(): void {
    this.dialogRef.close();
    this.dialog.open(SignInModalComponent, {
      data: { afterSignIn: this.data?.afterSignIn },
      autoFocus: false,
      maxWidth: '340px',
    });
  }
}
