import { isPlatformBrowser } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {
  computed,
  Inject,
  inject,
  Injectable,
  NgZone,
  PLATFORM_ID,
  signal,
} from '@angular/core';
import {
  Auth,
  AuthError,
  AuthProvider,
  authState,
  browserLocalPersistence,
  browserSessionPersistence,
  getAuth,
  GoogleAuthProvider,
  idToken,
  IdTokenResult,
  Persistence,
  signInWithEmailAndPassword,
  signInWithPopup,
  updatePassword,
  user,
  User,
} from '@angular/fire/auth';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { requireAppCheck } from '@jfw-library/shared/app-check';
import {
  AfterSignIn,
  AuthErrorCode,
  DealerPortalEnvironment,
  EcommerceMainEnvironment,
  JFWNewPasswordSchema,
  NewUserInfo,
  PasswordParseActions,
  Site,
} from 'common-types';
import { UserRecord } from 'firebase-admin/auth';
import { firstValueFrom, from, Observable, Subject } from 'rxjs';
import { map, shareReplay, switchMap, tap } from 'rxjs/operators';
import { EmailVerificationModalComponent } from '../../components/email-verification-modal/email-verification-modal.component';
import { SignInModalComponent } from '../../components/sign-in-modal/sign-in-modal.component';
import { AnonAuthService } from '../anon-auth/anon-auth.service';
import { AuthEmailActionsService } from '../auth-email-actions/auth-email-actions.service';

interface CreateAccountError {
  code: string;
  message: string;
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private reqHeader = new HttpHeaders({
    'Content-Type': 'application/json',
  });
  private readonly isBrowser = isPlatformBrowser(inject(PLATFORM_ID));

  private environment = signal<
    EcommerceMainEnvironment | DealerPortalEnvironment | undefined
  >(undefined);
  private isDealerPortal = computed(() => this.environment()?.dealerPortal);
  private _prodModeWideOpen = computed(() => {
    const environment = this.environment();
    if (!environment) return true;
    // if (environment.prod_mode_wide_open &&
    //   environment.prod_mode_refresh_key === '') {
    //   console.error(
    //     '%cProd Mode Settings are not aligned. Check prod_mode_refresh_key',
    //     'background-color:red;color:white',
    //   );
    // }
    return environment.prod_mode_wide_open;
  });

  private defaultSignInPersistence = computed(() => {
    const environment = this.environment();
    if (!environment) return browserSessionPersistence;
    if (environment.dealerPortal) return browserLocalPersistence;
    if (environment.default_sign_in_persistence === 'local')
      return browserLocalPersistence;
    return browserSessionPersistence;
  });

  private _showRememberMe = computed(() => {
    const environment = this.environment();
    if (!environment) return false;
    if (environment.dealerPortal) return false;
    return environment.default_sign_in_persistence === 'session';
  });

  public get showRememberMe() {
    return this._showRememberMe();
  }

  public get isProdModeWideOpen() {
    return this._prodModeWideOpen();
  }

  private readonly auth = inject(Auth);

  /** An observable of authentication state. The observer is only triggered on sign-in or sign-out; not on token refresh events. */
  public readonly authState$ = from(this.auth.authStateReady()).pipe(
    tap(() => console.log('authStateReady in authState$')),
    switchMap(() =>
      authState(this.auth).pipe(
        tap((auth) =>
          console.log('authState$', auth ? 'signed in' : 'signed out'),
        ),
      ),
    ),
    shareReplay(1), // only want to replay last emitted value (which is also current state)
  );

  /** An observable of current user's idToken. The observer is triggered for sign-in, sign-out, and token refresh events. */
  public readonly idToken$ = from(this.auth.authStateReady()).pipe(
    tap(() => console.log('authStateReady in idToken$')),
    switchMap(() =>
      idToken(this.auth).pipe(
        tap({
          subscribe: () => console.log('subscribed to idToken'),
          unsubscribe: () => console.log('unsubscribed from idToken'),
          next: (val) =>
            console.log('idToken:', val ? 'idToken found' : 'idToken is null'),
        }),
      ),
    ),
    shareReplay(1), // only want to replay last emitted value (which is also current state)
  );

  private async getIdToken(): Promise<string | null> {
    await this.auth.authStateReady();
    const idToken = await this.auth.currentUser?.getIdToken();
    if (idToken === undefined) return null;
    return idToken;
  }
  public get idToken(): Promise<string | null> {
    return this.getIdToken();
  }

  private async getIdTokenResult(): Promise<IdTokenResult | null> {
    await this.auth.authStateReady();
    const idTokenResult = await this.auth.currentUser?.getIdTokenResult();
    if (idTokenResult === undefined) return null;
    return idTokenResult;
  }
  public get idTokenResult(): Promise<IdTokenResult | null> {
    return this.getIdTokenResult();
  }

  /** An observable of user state. The observer is triggered for sign-in, sign-out, and token refresh events. */
  public readonly user$: Observable<User | null> = from(
    this.auth.authStateReady(),
  ).pipe(
    tap(() => console.log('authStateReady in user$')),
    switchMap(() =>
      user(this.auth).pipe(
        // startWith(undefined),
        tap({
          subscribe: () => console.log('subscribed to user'),
          unsubscribe: () => console.log('unsubscribed from user'),
          next: (val) =>
            console.log('user$:', val ? val.displayName : 'user is null'),
        }),
      ),
    ),
    shareReplay(1), // only want to replay last emitted value (which is also current state)
  );
  private async getUser() {
    await this.auth.authStateReady();
    return this.auth.currentUser;
  }
  /** Returns a promise of the current user in Auth or null if no user is signed in. */
  public get currentUser(): Promise<User | null> {
    return this.getUser();
  }

  /** An observable of the user's loggedIn status, which is defined as:
   * 1. the user is signed in, and
   * 2. the user's email is verified.
   * The observer is triggered for sign-in, sign-out, and token refresh events.
   */
  public isLoggedIn$ = this.user$.pipe(
    map((currentUser) => {
      if (!currentUser) return false;
      return currentUser && currentUser.emailVerified;
    }),
    tap({
      subscribe: () => console.log('subscribed to isLoggedIn$'),
      unsubscribe: () => console.log('unsubscribed from isLoggedIn$'),
      next: (val) => console.log('isLoggedIn$:', val),
    }),
    shareReplay(1), // only want to replay last emitted value (which is also current state)
  );

  private async getIsLoggedInAndEmailVerified(): Promise<boolean> {
    await this.auth.authStateReady();
    const user = this.auth.currentUser;
    if (!user) return false;
    return user && user.emailVerified;
  }

  /** Returns a promise of the user's loggedIn status, which is defined as:
   * 1. the user is signed in, and
   * 2. the user's email is verified.
   */
  public get isLoggedIn(): Promise<boolean> {
    return this.getIsLoggedInAndEmailVerified();
  }

  private customLinkId: string = '';

  private afterSignIn = new Subject<AfterSignIn>();
  public afterSignIn$ = this.afterSignIn.asObservable();

  constructor(
    @Inject('environment') private readonly _environment: any,
    private anonAuthService: AnonAuthService,
    private authEmailActionService: AuthEmailActionsService,
    private dialog: MatDialog,
    private httpClient: HttpClient,
    private router: Router,
    public ngZone: NgZone, // NgZone service to remove outside scope warning
  ) {
    console.log('AuthService.constructor');
    this.environment.set(_environment);
  }

  /** Sign up with email/password/name/phone
   * @param newUserInfo info needed to create new user
   * @param site needed for the sendEmailVerification api call so that the url link matches the correct site.
   * @param verifyEmailContinueUrl optional url to navigate to after email is verified.  This is sent as the continueUrl query param for the email verification link.  If not provided, the site's default continueUrl will be used.
   */
  public async createAccount(
    newUserInfo: NewUserInfo,
    site: Site,
    verifyEmailContinueUrl?: string,
  ): Promise<UserRecord> {
    const environment = this.environment();
    const isDealerPortal = environment?.dealerPortal;
    if (isDealerPortal) {
      throw new Error('createAccount is not supported in Dealer Portal');
    }
    const anonUserApiUrl = environment?.anon_user_rest_api_server_url;

    if (!anonUserApiUrl) {
      throw new Error(
        'anon_user_rest_api_server_url is not defined in the environment',
      );
    }

    return new Promise(async (resolve, reject) => {
      const source$ = this.httpClient
        .post<UserRecord | CreateAccountError>(
          `${anonUserApiUrl}/new-ecom-user`,
          newUserInfo,
          {
            ...requireAppCheck,
            headers: this.reqHeader,
            observe: 'response',
          },
        )
        .pipe(
          map((res) => {
            if (res.status === 201) {
              return res.body as UserRecord;
            } else {
              const error = res.body as CreateAccountError;
              throw new Error(error.message);
            }
          }),
        );

      source$.subscribe({
        next: async (userRecord) => {
          try {
            const userCredential = await signInWithEmailAndPassword(
              getAuth(),
              newUserInfo.email,
              newUserInfo.password,
            );
            userCredential.user.getIdToken(true);
            if (site === Site.DealerPortal) {
              await this.authEmailActionService.sendVerificationMail(
                site,
                verifyEmailContinueUrl,
              );
            }
            resolve(userRecord);
          } catch (error) {
            reject(error);
          }
        },
        error: (error) => reject(error),
      });
    });
  }

  /**
   * Checks if user has ecomAccess claim on their Firebase token.
   * @param forceRefresh forces a refresh of the token regardless of token expiration.  Default is false.
   * @param ignoreWhenInProdMode if true, then this method will return true if the environment.isProdModeWideOpen is true.  This is useful for when you want to ignore the ecomAccess claim when in prod mode.  Default is true.
   */
  public async getUserHasEcomAccess(
    forceRefresh: boolean = false,
    ignoreWhenInProdMode: boolean = true,
  ): Promise<boolean> {
    console.log('getUserHasEcomAccess');
    if (ignoreWhenInProdMode && this.isProdModeWideOpen) {
      console.log(
        'isProdModeWideOpen and ignoreWhenInProdMode is true.  Ignoring ecomAccess check.',
      );
      return true;
    }

    const user = await this.currentUser;
    if (!user) {
      console.log('No user found');
      return false;
    }
    const idToken = await user.getIdTokenResult(forceRefresh);
    if (idToken) {
      if (idToken.claims.ecomAccess === true) {
        return true;
      }
    }
    return false;
  }

  /** Sign in with email/password
   *
   * @param site site that the function is being called from
   * @param email email address to sign in with
   * @param password password to sign in with
   * @param redirectUrl optional url to navigate to after sign in.  This is sent as the continueUrl query param for the email verification link when email is not verified.  If not provided, no redirect will occur.
   * @param persistence optional persistence to use for the sign in.  Defaults to the environment.default_sign_in_persistence.  Used to determine if the user should be remembered across tabs or not.
   * @param loginFrom optional string to indicate where the login is coming from.  Defaults to 'other'.  Used to determine if the page should be reloaded after login.  If not provided, the page will be reloaded after sign-in.
   * @param cancelRedirect cancel the redirect for sign in
   * @param emailVerifyRetryCount optional number to indicate how many times the user has tried to verify their email address in a row (over and over).  This is passed on as a query param to the email-not-verified page and used to determine if the user should be allowed to retry verifying their email address.
   * @param afterSignIn optional AfterSignIn enum to determine additional action after successful sign in
   *
   * @returns void if successful, otherwise returns an error string.
   *
   *  */
  public async signInWithEmailAndPassword(
    site: Site,
    email: string,
    password: string,
    redirectUrl: string | null = null,
    persistence: Persistence = this.defaultSignInPersistence(),
    loginFrom: string = 'other',
    cancelRedirect: boolean = false,
    emailVerifyRetryCount?: number, // used to track how many times the user has tried to verify their email address in a row (over and over).  This is used to determine if the user should be allowed to retry verifying their email address.
    afterSignIn?: AfterSignIn,
  ): Promise<string | void> {
    try {
      console.log('AuthService.signIn');
      if (
        persistence &&
        persistence.type !== this.defaultSignInPersistence().type
      ) {
        console.log('setting persistence to', persistence.type);
        await this.auth.setPersistence(persistence);
      } else {
        console.log('persistence is already set to', persistence.type);
      }

      let retries = 0;

      while (retries < 2) {
        console.log('attempt', retries);

        let signInError: AuthErrorCode | string = '';

        /// ******** STEP 1: Authenticate with email/password ********
        const result = await signInWithEmailAndPassword(
          getAuth(),
          email,
          password,
        ).catch((error: AuthError) => {
          console.error('signInWithEmailAndPassword error!');
          console.error(error.code);
          signInError = error.code;
        });

        console.log('signInWithEmailAndPassword result:', result);

        /// If an error occurred during signInWithEmailAndPassword, return it
        if (signInError !== '') {
          return signInError;
        }

        /// If no result, then return a generic "error".  This should never happen, but just in case...
        if (!result) {
          return 'error';
        }

        // result is defined, so no error from signInWithEmailPassword, so UserCredential exists
        const { user } = result;

        /// ******** STEP 2:  Check if email is verified, and if not, navigate to email-not-verified page or open modal ********
        if (!user.emailVerified) {
          console.log('Email not verified. ');
          if (site === Site.DealerPortal) {
            const continueUrl = redirectUrl
              ? decodeURIComponent(redirectUrl)
              : undefined;
            const retry = emailVerifyRetryCount
              ? emailVerifyRetryCount
              : undefined;
            await this.router.navigate(['/email-not-verified'], {
              queryParams: { continueUrl, retry },
              onSameUrlNavigation: 'reload',
            });
            return;
          } else {
            this.dialog.closeAll();
            const result = await firstValueFrom(
              this.dialog
                .open(EmailVerificationModalComponent, {
                  data: { email: email },
                  minWidth: '340px',
                  maxWidth: '340px',
                })
                .afterClosed(),
            );
            if (result.success) {
              // sign out - sign back in will trigger authState() with email verified
              // await this.signOut();
              await this.auth.currentUser?.getIdToken(true);
            } else {
              return;
            }
          }
        } else {
          return await this.continueSignIn(
            user,
            redirectUrl,
            loginFrom,
            cancelRedirect,
            afterSignIn,
          );
        }
        retries++;
      }
    } catch (error: any) {
      console.error('Error occurred', error);
    }
  }

  private async continueSignIn(
    user: User,
    redirectUrl: string | null = null,
    loginFrom: string = 'other',
    cancelRedirect: boolean = false,
    afterSignIn?: AfterSignIn,
  ): Promise<string | undefined> {
    console.log('Email is verified.  Continuing...');

    /// ******** STEP 3:  Get the user's token ********
    const token = await user.getIdTokenResult(true);

    /// If no token was found, then return a generic "error"
    if (!token) {
      return 'error';
    }

    if (this.isDealerPortal()) {
      if (redirectUrl !== null) {
        console.log('TRYING TO GO TO ' + decodeURIComponent(redirectUrl));

        const decodedUrl: string = decodeURIComponent(redirectUrl);

        /// If logged in with ProdMode Restricted, redirect "Home" instead
        if (decodedUrl === '/prod' || decodedUrl === '/email-not-verified') {
          await this.router.navigateByUrl('/home').then(() => {
            if (loginFrom === 'other') {
              if (this.isBrowser) window.location.reload();
            }
          });

          return;
        }

        /// Otherwise go to the last requested Url
        await this.router
          .navigateByUrl(decodeURIComponent(redirectUrl))
          .then(() => {
            if (this.isBrowser) window.location.reload();
          });

        return;
      } else {
        // no redirectUrl, so go to home
        if (cancelRedirect === true) {
          return;
        }
        await this.router.navigateByUrl('/home').then(() => {
          if (this.isBrowser) window.location.reload();
        });

        return;
      }
    } else {
      // ecommerce
      if (afterSignIn) {
        this.afterSignIn.next(afterSignIn);
      } else {
        // for logging in via /V2login with email/password
        if (redirectUrl) {
          await this.router.navigate([redirectUrl]);
        }
      }
      return;
    }
  }

  /** Sign in with Google */
  public async signInWithGoogle(): Promise<void> {
    await this.authLogin(new GoogleAuthProvider());
  }

  /** Returns true if isProdModeWideOpen is true, or if the user has ecomAccess claims.  Otherwise returns false. */
  private hasEcomAccess(token: IdTokenResult): boolean {
    if (this.isProdModeWideOpen) {
      return true;
    }

    if (token && token?.claims && token?.claims?.ecomAccess) {
      return token.claims.ecomAccess === true;
    }

    return false;
  }

  /**
   * Opens the sign-in popup.  After sign-in, checks if user {@link hasEcomAccess}:
   * - If no ecomAccess, signs out user and redirects to /V2login?error=noaccess and reloads the page.
   *
   * - If ecomAccess, checks if isAnon:
   *    - If isAnon, sets the anonUser in localStorage with the token of the signed-in user, then signs out of Auth and redirects to /home.
   *    - If not isAnon, removes the anonUser and isAnon from localStorage, signs out of Auth, and redirects to /home.
   *
   * Alerts user if there is an error.
   *
   * @param provider the provider to use for sign-in.  Currently only GoogleAuthProvider is supported.
   * */
  private async authLogin(provider: AuthProvider): Promise<void> {
    console.log('authLogin');
    this.anonAuthService.clearAnonTokenCookies();
    try {
      // will throw an error if authentication fails
      const signInResult = await signInWithPopup(this.auth, provider);
      const token = await signInResult.user.getIdTokenResult(true);
      const hasEcomAccess = this.hasEcomAccess(token);
      if (!hasEcomAccess) {
        await this.signOut();
        return this.router.navigateByUrl('/V2login?error=noaccess').then(() => {
          if (this.isBrowser) window.location.reload();
          return;
        });
      } else {
        // if (isAnon) {
        //   await this.signOut();
        //   this.setAnonUser(token);
        //   this.router.navigate(['/home']);
        // } else {
        //   this.deleteAnonUser();
        //   this.router.navigate(['/home']);
        // }
        this.router.navigate(['/home']);
      }
    } catch (error) {
      if (this.isBrowser) window.alert('Sign In Error: ' + error);
      else console.error(error);
    }
  }

  /** Updates the currently signed-in user's password.
   * @param confirmNewPassword the new password to set
   * @returns a promise of void if successful, otherwise throws an error.
   */
  public async updatePassword(confirmNewPassword: string) {
    const parsedNewPassword =
      JFWNewPasswordSchema.safeParse(confirmNewPassword);
    if (!parsedNewPassword.success) {
      const parseFailAction: PasswordParseActions = {
        success: false,
        error: parsedNewPassword.error.message,
      };
      throw parseFailAction;
    }
    const currentUser = await this.currentUser;
    if (currentUser) {
      try {
        await updatePassword(currentUser, confirmNewPassword);
        return 'success';
      } catch (error: any) {
        console.log('UPDATE PASSWORD ERROR');
        console.log(error);
        if (error.code !== undefined) {
          console.log('error code: ' + error.code);
          return error.code;
        } else {
          return 'unknown-error';
        }
      }
    }
  }

  /** If there is a currently signed-in user,
   *  - signs out the user
   *  - clears localStorage
   *  - navigates to the continueUrl if provided, otherwise navigates to /home or /V2login
   * If there is no currently signed-in user, clears localStorage.
   * On error, clears localStorage.
   * @returns a promise of void
   */
  public async signOut(continueOptions?: {
    continueUrl: string;
    popSignInAfterContinueNavigation: boolean;
  }): Promise<void> {
    console.log('signOut...');
    const { continueUrl, popSignInAfterContinueNavigation } =
      continueOptions ?? {};
    const currentUser = await this.currentUser;
    console.log('currentUser before signOut:', currentUser);
    // Preserve the temp customLinkId in the state of the service.
    this.setCustomLinkIdInState();
    if (currentUser) {
      try {
        await this.auth.signOut();
        console.log('AuthService.signOut');
        this.clearLocalStorage();
        this.setCustomLinkIdLocalStorage(this.customLinkId);
        const environment = this.environment();
        const isDealerPortal = environment?.dealerPortal;
        if (isDealerPortal) {
          await this.router.navigateByUrl('/sign-in');
          return;
        }

        if (continueUrl) {
          if (popSignInAfterContinueNavigation) {
            await this.router.navigateByUrl(continueUrl);
            console.log(
              'navigated to:',
              continueUrl,
              '-- and opening SignInModal...',
            );
            this.dialog.open(SignInModalComponent, {
              autoFocus: false,
              maxWidth: '340px',
            });
            return;
          }

          await this.router.navigateByUrl(continueUrl);
          console.log('navigated to:', continueUrl);
          if (this.isBrowser) window.location.reload();
          return;
        }
        if (this.isProdModeWideOpen) {
          await this.router.navigateByUrl('/home');
          if (this.isBrowser) window.location.reload();
          return;
        }
        await this.router.navigateByUrl('/V2login');
        // if (this.isBrowser) window.location.reload();
        return;
      } catch (error) {
        console.log('error signing out');
        console.log(error);
        this.clearLocalStorage();
      }
    } else {
      console.log(
        'AuthService.signOut -> No currentUser found.  Skipping signOut, but clearing application state.',
      );
      this.clearLocalStorage();
      this.setCustomLinkIdLocalStorage(this.customLinkId);
      return;
    }
  }

  /** Clears local storage, clears selectedEvent, and clears cart.
   * This is necessary because we don't want the in-memory state (like selectedEvent, cart, etc.) to persist after sign-out.
   */
  clearLocalStorage(): void {
    console.log('Clearing localStorage');
    localStorage.clear();
  }

  /** Preserve customLinkId in local cache */
  private setCustomLinkIdInState(): void {
    const customLinkId = localStorage.getItem('customLinkId');
    if (customLinkId) {
      this.customLinkId = customLinkId;
    }
  }

  private setCustomLinkIdLocalStorage(customLinkId: string | null): void {
    if (customLinkId) {
      localStorage.setItem('customLinkId', customLinkId);
    }
  }
}
