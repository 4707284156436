import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID, inject } from '@angular/core';
import {
  generateUUID,
  getCartSubtotal,
  getMemberSubtotal,
  getPriorShippingAddress,
} from 'business-logic';
import {
  BillingAddress,
  Cart,
  CartProcessorStatus,
  CartShippingAddressForm,
  CheckoutState,
  EcommerceMainEnvironment,
  Event,
  EventMember,
  MemberCart,
  Promo,
  PurchaseItem,
  ShippingAddress,
  StyleGroup,
} from 'common-types';
import dayjs from 'dayjs';
import { BehaviorSubject } from 'rxjs';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class CartService {
  private checkoutState: CheckoutState = {
    payingForVisible: false,
    payingForReady: false,
    payingForExpanded: false,
    payingForError: false,
    payingForShowError: false,
    measurementsVisible: false,
    measurementsReady: false,
    measurementsExpanded: false,
    measurementsShowError: false,
    measurementsError: false,
    shippingVisible: false,
    shippingReady: false,
    shippingExpanded: false,
    shippingError: false,
    shippingShowError: false,
    paymentsVisible: false,
    paymentsReady: false,
    paymentsExpanded: false,
    paymentsError: false,
    paymentsShowError: false,
    orderDetailsVisible: false,
    orderDetailsReady: false,
    orderDetailsExpanded: false,
    orderDetailsError: false,
  };

  private checkoutStateSubject = new BehaviorSubject<CheckoutState>(
    this.getStartingCheckoutState(),
  );

  public checkoutState$ = this.checkoutStateSubject.asObservable();

  public checkoutCartSubject = new BehaviorSubject<Cart | undefined>(
    this.getStartingCartSubject(),
  );

  public canProceed = new BehaviorSubject<boolean>(false);
  private MAX_CART_LOCAL_STORAGE_AGE =
    this.environment.max_selected_cart_local_storage_age_secs;

  private isBrowser = isPlatformBrowser(inject(PLATFORM_ID));

  constructor(
    @Inject('environment') private environment: EcommerceMainEnvironment,
    private authService: AuthService,
  ) {}

  /** Removes 'checkout_state' item from local storage and pushes new checkoutStateSubject */
  public resetCheckoutState() {
    localStorage.removeItem('checkout_state');
    // console.log('Resetting Checkout State');
    this.checkoutState.payingForVisible = false;
    this.checkoutState.payingForReady = false;
    this.checkoutState.payingForExpanded = false;
    this.checkoutState.measurementsVisible = false;
    this.checkoutState.measurementsReady = false;
    this.checkoutState.measurementsExpanded = false;
    this.checkoutState.shippingVisible = false;
    this.checkoutState.shippingReady = false;
    this.checkoutState.shippingExpanded = false;
    this.checkoutState.paymentsVisible = false;
    this.checkoutState.paymentsReady = false;
    this.checkoutState.paymentsExpanded = false;
    // this.checkoutState.orderDetailsVisible = false;
    this.checkoutState.orderDetailsReady = false;
    this.checkoutState.orderDetailsExpanded = false;
    this.checkoutStateSubject.next(this.checkoutState);
  }

  private getStartingCartSubject(): Cart | undefined {
    // if (this.getCart() !== undefined){
    //   return
    // }
    return this.getCart();
  }

  /** Pushes the result of getStartingCheckoutState into checkoutStatusSubject */
  public initializeCheckoutState() {
    this.checkoutStateSubject.next(this.getStartingCheckoutState());
  }

  public getStartingCheckoutState(): CheckoutState {
    if (!this.isBrowser) {
      return this.checkoutState;
    }
    //return this.startingCheckoutState;

    // console.log('getStartedCheckoutState');

    if (localStorage.getItem('checkout_state') !== null) {
      // console.log('Found prior checkout_state');
      // console.log(localStorage.getItem('checkout_state'));

      let savedCheckoutState: CheckoutState = JSON.parse(
        localStorage.getItem('checkout_state')!,
      );

      return savedCheckoutState;
    } else {
      return this.checkoutState;
    }
  }

  public cleanCartWithNewEvent(newEventId: string) {
    console.log('cleanCartWithNewEvent');
    if (localStorage.getItem('cart') !== null) {
      let cart: Cart = JSON.parse(localStorage.getItem('cart')!);

      if (cart.eventId === newEventId) {
        return;
      }

      let purchaseItems = cart.cartPurchaseItems;

      let newCart: Cart = {
        id: '1', // We're just putting this here because id is *not* optional right now. It gets resolved with the creation/update steps
        eventId: newEventId,
        eventName: '',
        eventType: '',
        eventDate: 0,
        userId: undefined,
        members: undefined,
        payment: {
          cardName: '',
          cardType: '',
          cardNumber: '',
          cvv: '',
          expirationDate: new Date(0),
        },
        subtotal: 0,
        damageWaiver: 0,
        taxes: 0,
        shippingCost: 0,
        discounts: 0,
        cartPurchaseItems: purchaseItems,
        cartProcessorStatus: CartProcessorStatus.PENDING,
      };

      this.updateCart(newCart, 'CartService -- cleanCartWithNewEvent');
    }

    return;
  }

  public updateCheckoutState(state: Partial<CheckoutState>) {
    const currentState = this.checkoutStateSubject.getValue();
    const newState = { ...currentState, ...state };
    this.checkoutStateSubject.next(newState);
    localStorage.setItem('checkout_state', JSON.stringify(newState));
  }

  private isCartLocalStorageOutdated(): boolean {
    let dateDiff: number = 999999999; // set default diff to extreme

    // If 'cart_updated' exists in local storage, calculate age
    if (localStorage.getItem('cart_updated') !== null) {
      let updateDate = dayjs(localStorage.getItem('cart_updated'));
      let currentDate = dayjs(Date.now());

      dateDiff = currentDate.diff(updateDate) / 1000;
      // console.log('cart dateDiff: ' + dateDiff);
    }

    return dateDiff > this.MAX_CART_LOCAL_STORAGE_AGE;
  }

  public getCartFromLocalStorage(
    byPassExpirationCheck: boolean = false,
  ): Cart | undefined {
    if (!this.isBrowser) return undefined;

    const cartLS = localStorage.getItem('cart');

    if (cartLS === null) {
      // console.log('NO CART CACHE');
      return undefined;
    }

    if (!byPassExpirationCheck && this.isCartLocalStorageOutdated()) {
      // console.log('CACHE OUTDATED');
      return undefined;
    }

    return JSON.parse(cartLS);
  }

  private setCartInLocalStorage(cart: Cart | undefined): void {
    if (cart === undefined) {
      return undefined;
    }

    localStorage.setItem('cart', JSON.stringify(cart));
    localStorage.setItem('cart_updated', dayjs(Date.now()).toString());
  }

  /** Remove 'cart' and 'cart_updated' from local storage.  Push undefined into checkoutCartSubject. */
  public clearCartCache(): void {
    localStorage.removeItem('cart');
    localStorage.removeItem('cart_updated');
    this.checkoutCartSubject.next(undefined);
    console.log('Cart Cache Cleared');
    //this.checkoutCartSubject.complete();
  }

  public updateCart(cart: Cart | undefined, source: string): Cart | undefined {
    if (cart !== undefined) {
      this.setCartInLocalStorage(cart);
      this.checkoutCartSubject.next(cart);
      console.log('Cart Updated by', source);
    }
    return cart;
  }

  public deleteUserCart() {
    this.clearCartCache();
    return;
  }

  private createPriorShippingDetailsMap(
    priorMembers: Array<MemberCart> | undefined = undefined,
  ): Map<string, ShippingAddress> {
    let membersShippingDetails = new Map<string, ShippingAddress>();
    if (priorMembers) {
      priorMembers.forEach((member: any) => {
        membersShippingDetails.set(member.id, member.shipping.address);
      });
    }
    return membersShippingDetails;
  }

  public async buildCart(
    event: Event,
    priorCart: Cart | undefined = undefined,
  ): Promise<Cart | undefined> {
    const newCart = await this.createCartFromEvent(event, priorCart);
    if (newCart !== undefined) {
      this.updateCart(newCart, 'CartService -- buildCart');
      // this.updateCartLooks(event); // This is not needed anymore
      return newCart;
    } else {
      return undefined;
    }
  }

  private getSessionPayingForMembers(): string[] {
    const lsPayingForMembers = localStorage.getItem('payingFor');
    const payingForMembers: string[] = lsPayingForMembers
      ? JSON.parse(lsPayingForMembers)
      : [];
    return payingForMembers;
  }

  private async createCartFromEvent(
    event: Event,
    priorCart: Cart | undefined = undefined,
  ): Promise<Cart | undefined> {
    // console.log('priorCart', priorCart);
    // console.log('event', event);
    const currentUserId = (await this.authService.currentUser)?.uid;
    if (currentUserId === undefined) {
      console.error('No current user found');
      return undefined;
    }

    console.log('Creating Cart with currentUser', currentUserId);

    const sessionSelectedMembers = this.getSessionPayingForMembers();

    console.log('sessionSelectedMembers:' + sessionSelectedMembers);
    const payingForMembers = event.members.filter((member: EventMember) => {
      return (
        sessionSelectedMembers.includes(member.id!) &&
        (member.memberProgress === undefined ||
          member.memberProgress.paid === undefined)
      );
    });
    if (payingForMembers.length <= 0) {
      // alert('An error occurred while updating your cart. Please try again.');
    }

    // console.log('payingForMembers', payingForMembers);

    const priorMembers = priorCart?.members;

    const priorPaymentRefResponse = priorCart?.paymentRefResponse;

    const priorBillingAddress = priorCart?.billingAddress;

    const priorShippingDetails =
      this.createPriorShippingDetailsMap(priorMembers);

    const cartMembers = payingForMembers.map((member) => {
      const subTotal = getMemberSubtotal(member);

      let priorShippingAddress: ShippingAddress | undefined;

      let memberEventAddress = member.shippingAddress;

      const shippingAddress: ShippingAddress = {
        name: '',
        streetAddress1: '',
        streetAddress2: '',
        city: '',
        state: '',
        zip: '',
        phone: '',
      };

      if (member.id !== undefined) {
        priorShippingAddress = getPriorShippingAddress(member.id, priorCart);
      }

      if (memberEventAddress === undefined) {
        memberEventAddress = shippingAddress;
      }

      if (priorShippingAddress !== undefined) {
        member.shippingAddress = priorShippingAddress;
      }

      const damageWaiverFee: number = this.getDamageWaiverFee(member);

      const memberCart: MemberCart = {
        id:
          member.id !== undefined
            ? member.id
            : (Math.random() * 100.0).toString(),
        member: member,
        promos: [],
        damageWaiver: damageWaiverFee,
        subtotal: subTotal,
        taxes: -1,
        total: 0,
        shipping: {
          //address: shippingAddress,
          service: '',
          cost: 0,
        },
      };

      return memberCart;
    });

    let cartSubtotal = 0;
    let cartDamageWaiver = 0;
    let cartTaxes = 0;
    let cartShipping = 0;
    let cartDiscounts = 0;

    cartMembers.forEach((memberCart) => {
      cartSubtotal += memberCart.subtotal;
      cartDamageWaiver += memberCart.damageWaiver;
      cartTaxes += Math.max(memberCart.taxes, 0);
      cartShipping += memberCart.shipping.cost;

      memberCart.promos.forEach((promo: Promo) => {
        if (promo.dollarOff !== undefined) {
          cartDiscounts += promo.dollarOff;
        }
        if (promo.percentOff !== undefined) {
          cartDiscounts += memberCart.subtotal * (promo.percentOff / 100.0);
        }
      });
    });

    /// Get Purchase items from current cart (if exists)
    const currentCart: Cart | undefined = this.getCart();
    const purchaseItems: PurchaseItem[] =
      currentCart !== undefined && currentCart.cartPurchaseItems !== undefined
        ? currentCart.cartPurchaseItems
        : [];

    const newCart: Cart = {
      id: generateUUID(this.isBrowser),
      eventId: event.id,
      eventDate: event.eventDate,
      eventType: event.eventType,
      eventName: event.eventName,
      userId: currentUserId,
      members: cartMembers,
      payment: {
        cardName: '',
        cardType: '',
        cardNumber: '',
        cvv: '',
        expirationDate: new Date(0),
      },
      subtotal: cartSubtotal,
      damageWaiver: cartDamageWaiver,
      taxes: cartTaxes,
      transferredByDealer: event.dealerInfo?.transferredByDealer?.name ?? '',
      shippingCost: cartShipping,
      discounts: cartDiscounts,
      cartPurchaseItems: purchaseItems,
      commissionAccount: event.dealerId,
      cartProcessorStatus: CartProcessorStatus.PENDING,
    };

    // let priorShippingFormDetails = priorCart?.shippingAddressForms;
    const cleanShippingFormDetails = this.getCleanShippingFormDetails(
      newCart,
      priorCart,
    );

    if (cleanShippingFormDetails !== undefined) {
      newCart.shippingAddressForms = cleanShippingFormDetails;
    }

    // Update Cart Subtotal
    newCart.subtotal = getCartSubtotal(newCart);
    newCart.eventLastUpdated = event.lastUpdated;
    newCart.eventName = event.eventName;
    newCart.eventDate = event.eventDate;

    if (priorPaymentRefResponse !== undefined) {
      newCart.paymentRefResponse = priorPaymentRefResponse;
    }

    if (priorBillingAddress !== undefined) {
      newCart.billingAddress = priorBillingAddress;
    }

    console.log('newCart created', newCart);

    return newCart;
  }

  private getDamageWaiverFee(member: EventMember) {
    let foundRental: boolean = false;

    member.memberLook?.styles.forEach((style) => {
      if (style.userCartSelectedStyleGroup === StyleGroup.Rental) {
        foundRental = true;
      }
    });

    if (foundRental) {
      return 10;
    }

    return 0;
  }

  private isCartMember(cart: Cart, memberId: string) {
    let isMember: boolean = false;

    cart.members?.forEach((member) => {
      if (member.id === memberId) {
        isMember = true;
        return;
      }
    });

    return isMember;
  }

  private cleanShippingForm(cart: Cart, addressForm: CartShippingAddressForm) {
    if (cart === undefined || addressForm === undefined) {
      return undefined;
    }

    let validMemberIds: string[] = [];

    addressForm.associatedMemberIds?.forEach((id) => {
      if (this.isCartMember(cart, id)) {
        validMemberIds.push(id);
      }
    });

    addressForm.associatedMemberIds = validMemberIds;

    return addressForm;
  }

  private getCleanShippingFormDetails(
    newCart: Cart,
    priorCart: Cart | undefined,
  ): CartShippingAddressForm[] | undefined {
    let shippingForms: CartShippingAddressForm[] = [];

    if (priorCart === undefined) {
      return undefined;
    }
    priorCart.shippingAddressForms?.forEach((address) => {
      let newShippingForm: CartShippingAddressForm | undefined =
        this.cleanShippingForm(newCart, address);

      if (newShippingForm !== undefined) {
        shippingForms.push(newShippingForm);
      }
    });

    if (shippingForms.length > 0) {
      return shippingForms;
    } else {
      return undefined;
    }
  }

  public getCart(byPassExpirationCheck: boolean = true): Cart | undefined {
    return this.getCartFromLocalStorage(byPassExpirationCheck);
  }

  public updateCartMember(cart: Cart | undefined, member: MemberCart) {
    if (cart === undefined || cart.members === undefined) {
      return;
    }

    const memberIndex = cart.members.findIndex((oldMember) => {
      return member.id === oldMember.id;
    });

    cart.members[memberIndex] = member;
    this.updateCart(cart, 'CartService -- updateCartMember');
  }

  /** Assign the first cart member's member property to be the first event member */
  // private updateCartMembers(event: Event, cart: Cart | undefined): Cart | undefined {
  //   // console.log('updateCartMembers');
  //   // console.log('event', event);

  //   if (cart !== undefined && cart.members !== undefined) {
  //     cart.members[0].member = event.members[0];

  //     cart = this.updateCart(cart);
  //   }

  //   return cart;
  // }

  /** Updates the cart members' memberLook with the corresponding event member's memberLook */
  // private updateCartLooks(event: Event): Cart | undefined {
  //   let cart = this.getCart();

  //   if (cart === undefined) {
  //     return undefined;
  //   }

  //   let foundMember: boolean = false;

  //   for (let i = 0; i < event.members.length; i++) {
  //     const member: EventMember = event.members[i];
  //     const memberId = member.id;
  //     if (memberId === undefined) {
  //       console.error('Member id is undefined');
  //       return undefined;
  //     }

  //     if (cart.members !== undefined) {
  //       const memberIndex = cart.members.findIndex((cartMember) => {
  //         return memberId === cartMember.member.id;
  //       });

  //       if (memberIndex > -1) {
  //         cart.members[memberIndex].member.memberLook = member.memberLook;
  //         foundMember = true;
  //         console.log('Found member and updated memberLook');
  //       }
  //     }
  //   }

  //   if (!foundMember) {
  //     cart = this.updateCartMembers(event, cart);
  //   }

  //   if (cart !== undefined) {
  //     cart = this.updateCart(cart);
  //   }

  //   return cart;
  // }

  public getMemberShippingAddressesFromCart(
    cart: Cart,
  ): Array<ShippingAddress> {
    let shippingAddresses: ShippingAddress[] = [];
    // Check assigned shipping forms for a checkout member
    let hasShippingForm = cart?.shippingAddressForms?.some(
      (shippingForm: CartShippingAddressForm) => {
        return shippingForm?.associatedMemberIds?.length;
      },
    );
    // If there is a multi form, shipping address found here
    if (
      hasShippingForm &&
      cart?.members !== undefined &&
      cart?.members?.length > 1
    ) {
      cart?.shippingAddressForms?.forEach((shippingAddress) => {
        if (shippingAddress?.associatedMemberIds?.length! > 0) {
          shippingAddresses.push(shippingAddress.shippingAddress);
        }
      });
    } else {
      // Use member object in members array for shipping address otherwise
      cart?.members?.forEach((member) => {
        if (member.member.shippingAddress) {
          shippingAddresses.push(member.member.shippingAddress);
        }
      });
    }
    return shippingAddresses;
  }

  public getBillingAddressFromCart(cart: Cart): BillingAddress {
    if (cart?.billingAddressSameAsShipping) {
      return this.getMemberShippingAddressesFromCart(cart)[0];
    } else {
      return cart?.billingAddress!;
    }
  }
}
